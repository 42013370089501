import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import moment from 'moment'
import { get, MENUORDERESTIMATE_URL } from '../../utility/api'
import HukkaSelect from '../hukkaSelect'
import {
  setMenuOrderEstimate
} from '../../actions'

const WeekFoods = () => {
	const { restaurants, menuOrderEstimate } = useSelector(
    state => state
  )
	const [selectedRestaurant, setSelectedRestaurant] = useState(null)
  const [customerAmount, setCustomerAmount] = useState(null)
  const [weekNumber, setWeekNumber] = useState(moment().isoWeek())
  const [yearNumber, setYearNumber] = useState(moment().isoWeekYear())
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const monday = moment()
    .isoWeek(weekNumber)
    .isoWeekYear(yearNumber)
    .startOf('week')

  const restaurantOptions =
    restaurants &&
    restaurants.slice().sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map(r => ({
      value: r.id,
      label: r.name,
    }))

  const fetchWeekEstimation = async (restaurant, newMonday) => {
    let payload = {
      from: newMonday ? newMonday.format('YYYY-MM-DD') : monday.format('YYYY-MM-DD'),
      to: newMonday ? newMonday.clone().add(6, 'days').format('YYYY-MM-DD') : monday.clone().add(6, 'days').format('YYYY-MM-DD')
    }
    let restaurantId = restaurant ? restaurant : selectedRestaurant.value
    const resp = await get(MENUORDERESTIMATE_URL + restaurantId + '/', payload)

    if (resp.status === 200) {
      dispatch(setMenuOrderEstimate(resp.data))
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const changeWeekNumber = delta => {
    let thisWeek = moment().isoWeekYear(yearNumber).isoWeek(weekNumber)
    let changedWeekNumber = thisWeek.add(delta, 'weeks').isoWeek()
    let previousWeekNumber = thisWeek.add((delta * -1), 'weeks').isoWeek()
    let changedYearNumber = yearNumber
    if ((previousWeekNumber + 50) < changedWeekNumber) {
      changedYearNumber = yearNumber - 1
      setYearNumber(changedYearNumber)
    } else if ((previousWeekNumber - 50) > changedWeekNumber) {
      changedYearNumber = yearNumber + 1
      setYearNumber(changedYearNumber)
    }
    setWeekNumber(changedWeekNumber)
    const newMonday = moment().isoWeekYear(changedYearNumber).isoWeek(changedWeekNumber).startOf('isoWeek')
    setLoading(true)
    fetchWeekEstimation(null, newMonday)
  }

  const setCurrentWeek = delta => {
    let currentWeek = moment().isoWeek()
    let currentYear = moment().isoWeekYear()
    setWeekNumber(currentWeek)
    const newMonday = moment().isoWeekYear(currentYear).isoWeek(currentWeek).startOf('isoWeek')
    setLoading(true)
    fetchWeekEstimation(null, newMonday)
  }

  const weekNavigator = (
    <div className="column is-full">
    	<p
    		className="title is-2 is-size-4-mobile is-uppercase"
    		style={{margin: '0'}}
    	>
    		{t('Viikko')} {weekNumber}
    	</p>
      <button
        type="button"
        disabled={!selectedRestaurant}
        className="button is-small is-primary"
        style={{ margin: '5px', minWidth: '125px' }}
        onClick={() => changeWeekNumber(-1)}
      >
        <Trans>edellinen</Trans>
      </button>

      <button
        type="button"
        disabled={!selectedRestaurant}
        className="button is-small is-primary"
        style={{ margin: '5px', minWidth: '125px' }}
        onClick={() => {
          setCurrentWeek()
        }}
      >
        <Trans>Takaisin nykyiseen viikkoon</Trans>
      </button>

      <button
        type="button"
        disabled={!selectedRestaurant}
        className="button is-small is-primary"
        style={{ margin: '5px', minWidth: '125px' }}
        onClick={() => changeWeekNumber(1)}
      >
        <Trans>seuraava</Trans>
      </button>
    </div>
  )

  const estimateData = () => {
    let html = []

    if (selectedRestaurant) {
      for (let i = 0;i < 7; i++) {
        let foodsHtml = []
        let date = monday.clone().add(i, 'days')
        let dateStr = date.format('ddd DD.MM.')
        let foodsOfDay = menuOrderEstimate && menuOrderEstimate.servings && menuOrderEstimate.servings.filter(m => moment(m.servingDatetime).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))
        if (foodsOfDay) {
          foodsOfDay.forEach(function (food) {
            if (food.servingCount > 3) {
              foodsHtml.push(<>
                <tr style={{padding: '10px 0'}}>
                  <td>
                    <p
                      className="title is-3 is-size-4-mobile"
                      style={{lineHeight: '2'}}
                    >
                      {food.foodName}
                    </p>
                  </td>
                  <td style={{width: '20px'}}></td>
                  <td>
                    <p
                      className="title is-3 is-size-4-mobile text-secondary-color"
                      style={{lineHeight: '2'}}
                    >
                      { customerAmount ? ((parseFloat(food.estimate) / parseFloat(food.avgCustomers) * customerAmount / 1000).toFixed(1) + 'kg') : t('Aseta asiakasmäärä') }
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p
                      style={{lineHeight: '2'}}
                      className="text-secondary-color"
                    >
                      {t('Arvio perustuu')} {food.servingCount} {t('aikaisempaan tarjoilukertaan')}
                    </p>
                  </td>
                  <td style={{width: '20px'}}></td>
                  <td>
                    <button
                      type="button"
                      className="button is-small text-secondary-color"
                      onClick={() => {
                        window.location.href = '/waste-report?restaurants=' + selectedRestaurant.value + '&startDate=' + moment(food.firstServing).format('DD.MM.YYYY') + '&endDate=' + moment(food.lastServing).format('DD.MM.YYYY') + '&scope=foods&foods=' + food.food
                      }}
                    >
                      <Trans>Kattaukset</Trans>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p
                      className="title is-1 is-size-2-mobile"
                      style={{lineHeight: '1'}}
                    >-</p>
                  </td>
                </tr>
              </>)
            } else {
              foodsHtml.push(<>
                <tr style={{padding: '10px 0'}}>
                  <td>
                    <p
                      className="title is-3 is-size-4-mobile"
                      style={{lineHeight: '2'}}
                    >
                      {food.foodName}
                    </p>
                  </td>
                  <td style={{width: '20px'}}></td>
                  <td>
                    <p
                      className="title is-3 is-size-4-mobile text-secondary-color"
                      style={{lineHeight: '2'}}
                    >
                      -
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p
                      style={{lineHeight: '2'}}
                      className="text-secondary-color"
                    >
                      {t('Arviota ei näytetä, koska ruualla alle 3 tarjoilukertaa.')}
                    </p>
                  </td>
                  <td style={{width: '20px'}}></td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p
                      className="title is-1 is-size-2-mobile"
                      style={{lineHeight: '1'}}
                    >-</p>
                  </td>
                </tr>
              </>)
            }
          })
        }
        html.push(<div>
          <p
            className="title is-3 is-size-4-mobile is-uppercase"
            style={{margin: '0'}}
          >
            <span style={{borderBottom: '4px solid #000'}}>
              {dateStr}
            </span>
          </p>
          <table className="weekEstimationTable">
            <tbody>
              {foodsHtml}
            </tbody>
          </table>
        </div>)
      }
    }
    
    return (
      <div>
        {html}
      </div>
    )
  }

	return (
	<div>
  	<h1 className="title is-1 is-size-3-mobile is-uppercase"><Trans>Viikkomenekkiarvio</Trans></h1>

    <div style={{padding: '10px'}}>
    	<div className="control">
        <div className="select is-large is-fullwidth hukkaselect">
          <HukkaSelect
            options={restaurantOptions}
            value={selectedRestaurant}
            placeholder={t('Valitse ravintola')}
            onChange={e => {
              setSelectedRestaurant(e)
              setLoading(true)
              fetchWeekEstimation(e.value)
            }}
          />
        </div>
      </div>
    </div>
    <div style={{padding: '10px'}}>
      <div style={{ textAlign: 'left' }}><Trans>Asiakasmäärä</Trans></div>
      <div
        className="field"
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <input
          className="is-large has-text-primary is-expanded"
          placeholder={0}
          style={{ fontSize: '16px', flex: 1 }}
          type="number"
          value={customerAmount}
          onChange={e => {
            setCustomerAmount(e.target.value)
          }}
        />
      </div>
    </div>
    {weekNavigator}
    {loading === false && estimateData()}
    {loading === true && selectedRestaurant && (
      <p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>Ladataan...</p>
    )}
	</div>
)}

export default WeekFoods
