import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import moment from 'moment'
import { get, post, MEALSERVINGS_URL, SERVINGPREDICTION_URL } from '../../utility/api'
import HukkaSelect from '../hukkaSelect'
import ErrorMessage from '../errorMessage'
import {
  setServingPredictions,
  setServingPredictionStatuses
} from '../../actions'

const WeekFoodsAi = () => {
	const { restaurants, mealTypes, servingPredictions, servingPredictionStatuses, hukkaProfiles, hukkaServices, hukkaServiceSubscriptions } = useSelector(
    state => state
  )
  const { id, loggedIn, rou:readOnlyUser } = useSelector(state => state.user) || { loggedIn: false, readOnlyUser: false }
	const [selectedRestaurant, setSelectedRestaurant] = useState(null)
  const [currentFormFields, setCurrentFormFields] = useState([])
  const [currentFormFieldValues, setCurrentFormFieldValues] = useState([])
  const [selectedField, setSelectedField] = useState('')
  const [selectedFieldValue, setSelectedFieldValue] = useState('')
  const [mealServings, setMealServings] = useState([])
  const [weekNumber, setWeekNumber] = useState(moment().isoWeek())
  const [yearNumber, setYearNumber] = useState(moment().isoWeekYear())
  const [errors, setErrors] = useState(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const monday = moment()
    .isoWeek(weekNumber)
    .isoWeekYear(yearNumber)
    .startOf('week')

  const restaurantOptions =
    restaurants &&
    restaurants.slice().sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map(r => ({
      value: r.id,
      label: r.name,
    }))

  const getMealServings = async (from, to, res) => {
    let payload = {
      from: from,
      to: to,
      restaurant: res ? res : selectedRestaurant.value
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      setMealServings(resp.data)
      resp.data.forEach(serving => {
        let servingKey = serving.id.toLowerCase().replaceAll('-', '')
        dispatch(setServingPredictionStatuses({id: servingKey, status: 'loading'}))
        getServingPredictions(serving.id)
      })
    } else {
      setErrors(resp.data)
    }
    setLoading(false)
  }

  const getServingPredictions = async (serving) => {
    let payload = {
      servings: [{id: serving}]
    }
    let servingKey = serving.toLowerCase().replaceAll('-', '')
    
    if (!servingPredictions[servingKey]) {
      const resp = await post(SERVINGPREDICTION_URL, payload)
      /*let servingObj = {}
      servingObj[serving] = {
        "be3f4124-ed98-45c8-a0c4-dbfbf59ecf1c": {
          "name": "Kasvislasagnettea",
          "ratio": 0.8481739484991849,
          "grams": 60151,
          "customers": 425
        },
        "5c7695a7-ce3b-43c7-a607-5a0ee115a931": {
          "name": "Usa-punakaali-maissisalaattia",
          "ratio": 0.15182605150081502,
          "grams": 10767,
          "customers": 425
        }
      }
      let resp = {
        data: {
          servings: servingObj
        }
      }*/
      if (resp.status === 200 && resp.data && resp.data.servings && Object.keys(resp.data.servings).length > 0) {
      //if (true) {
        if (currentFormFields.indexOf(servingKey) === -1) {
          let currentFields = currentFormFields
          currentFields.push(servingKey)
          setCurrentFormFields(currentFields)
        }
        let index = currentFormFields.indexOf(servingKey)
        let fieldValues = currentFormFieldValues
        let serv = resp.data.servings[Object.keys(resp.data.servings)[0]]
        fieldValues[index] = serv[Object.keys(serv)[0]].customers
        setCurrentFormFieldValues(fieldValues)
        
        dispatch(setServingPredictions(resp.data.servings))
        dispatch(setServingPredictionStatuses({id: servingKey, status: 'ready'}))
      } else {
        setErrors(resp.data)
        dispatch(setServingPredictionStatuses({id: servingKey, status: 'error'}))
      }
    } else {
      dispatch(setServingPredictionStatuses({id: servingKey, status: 'ready'}))
    }
  }

  const changeWeekNumber = delta => {
    let thisWeek = moment().isoWeekYear(yearNumber).isoWeek(weekNumber)
    let changedWeekNumber = thisWeek.add(delta, 'weeks').isoWeek()
    let previousWeekNumber = thisWeek.add((delta * -1), 'weeks').isoWeek()
    let changedYearNumber = yearNumber
    if ((previousWeekNumber + 50) < changedWeekNumber) {
      changedYearNumber = yearNumber - 1
      setYearNumber(changedYearNumber)
    } else if ((previousWeekNumber - 50) > changedWeekNumber) {
      changedYearNumber = yearNumber + 1
      setYearNumber(changedYearNumber)
    }
    setWeekNumber(changedWeekNumber)
    let newMonday = moment().isoWeekYear(changedYearNumber).isoWeek(changedWeekNumber).startOf('isoWeek').format('YYYY-MM-DD')
    let newSunday = moment().isoWeekYear(changedYearNumber).isoWeek(changedWeekNumber).endOf('isoWeek').format('YYYY-MM-DD')
    setLoading(true)
    getMealServings(newMonday, newSunday)
  }

  const setCurrentWeek = delta => {
    let currentWeek = moment().isoWeek()
    let currentYear = moment().isoWeekYear()
    setWeekNumber(currentWeek)
    const newMonday = moment().isoWeekYear(currentYear).isoWeek(currentWeek).startOf('isoWeek')
    setLoading(true)
    fetchWeekEstimation(null, newMonday)
  }

  const weekNavigator = (
    <div className="column is-full">
    	<p
    		className="title is-2 is-size-4-mobile is-uppercase"
    		style={{margin: '0'}}
    	>
    		{t('Viikko')} {weekNumber}
    	</p>
      <button
        type="button"
        disabled={!selectedRestaurant}
        className="button is-small is-primary"
        style={{ margin: '5px', minWidth: '125px' }}
        onClick={() => changeWeekNumber(-1)}
      >
        <Trans>edellinen</Trans>
      </button>

      <button
        type="button"
        disabled={!selectedRestaurant}
        className="button is-small is-primary"
        style={{ margin: '5px', minWidth: '125px' }}
        onClick={() => {
          setCurrentWeek()
        }}
      >
        <Trans>Takaisin nykyiseen viikkoon</Trans>
      </button>

      <button
        type="button"
        disabled={!selectedRestaurant}
        className="button is-small is-primary"
        style={{ margin: '5px', minWidth: '125px' }}
        onClick={() => changeWeekNumber(1)}
      >
        <Trans>seuraava</Trans>
      </button>
    </div>
  )

  const estimateData = () => {
    let html = []

    if (selectedRestaurant) {
      for (let i = 0;i < 7; i++) {
        let servingsHtml = []
        let date = monday.clone().add(i, 'days')
        let dateStr = date.format('ddd DD.MM.')
        let servingsOfDay = mealServings && mealServings.filter(m => moment(m.servingDatetime).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))
        if (servingsOfDay) {
          servingsOfDay.forEach(function (serving) {
            let servingKey = serving.id.toLowerCase().replaceAll('-', '')
            servingsHtml.push(<tr key={serving.id+servingKey} style={{padding: '10px 0'}}>
              <td>
                <p
                  className="title is-3 is-size-4-mobile"
                  style={{lineHeight: '2'}}
                >
                  { mealTypes.find(mt => mt.id === serving.mealType).name + ' ' + t('klo') + ' ' + moment(serving.servingDatetime).format('HH:mm') }
                </p>
              </td>
              <td style={{width: '50px'}}></td>
              <td>
                <button
                  type="button"
                  className="button is-small text-secondary-color"
                  disabled={servingPredictionStatuses[servingKey] && (servingPredictionStatuses[servingKey] === 'loading' || servingPredictionStatuses[servingKey] === 'ready')}
                  onClick={() => {
                    dispatch(setServingPredictionStatuses({id: servingKey, status: 'loading'}))
                    getServingPredictions(serving.id)
                  }}
                >
                  {servingPredictionStatuses[servingKey] === 'loading' ? t('Haetaan arviota...') : (servingPredictionStatuses[servingKey] === 'ready' ? t('Arvio haettu') : (servingPredictionStatuses[servingKey] === 'error' ? t('Hae arvio uudelleen') : t('Hae arvio'))) }
                </button>
              </td>
            </tr>)
            if (servingPredictions[servingKey]) {
              let customerAmountSet = false
              let fieldIndex = -1
              Object.keys(servingPredictions[servingKey]).forEach(function (key) {
                if (!customerAmountSet) {
                  if (servingPredictions[servingKey][key].customers) {
                    servingsHtml.push(<p style={{textAlign: 'left', paddingBottom: '20px'}}><b>{t('Koneoppimisen arvioima asiakasmäärä:')} {servingPredictions[servingKey][key].customers}</b></p>)
                    fieldIndex = currentFormFields.indexOf(servingKey)
                    servingsHtml.push(<span style={{textAlign: 'left', margin: '0 0 0 -24px', padding: '0'}}><b>{t('Arvion asiakasmäärä')}: </b></span>)
                    servingsHtml.push(<input
                        type="number"
                        value={selectedField === servingKey ? selectedFieldValue : currentFormFieldValues[fieldIndex]}
                        onFocus={() => {
                          setSelectedField(servingKey)
                          let index = currentFormFields.findIndex(cff => cff === servingKey)
                          setSelectedFieldValue(currentFormFieldValues[index])
                        }}
                        onChange={e => {
                          let values = currentFormFieldValues
                          let index = currentFormFields.findIndex(cff => cff === servingKey)
                          let fieldValue = e.target.value
                          values[index] = fieldValue
                          setCurrentFormFieldValues(values)
                          setSelectedFieldValue(fieldValue)
                        }}
                      />)
                    customerAmountSet = true
                  }
                }
                servingsHtml.push(<tr key={servingKey+key} style={{padding: '10px 0'}}>
                  <td>
                    <p
                      style={{lineHeight: '1.5'}}
                    >
                      { servingPredictions[servingKey][key].name }
                    </p>
                  </td>
                  <td style={{width: '50px'}}></td>
                  <td>
                    <p
                      style={{lineHeight: '1.5'}}
                    >
                      { servingPredictions[servingKey][key].grams ? ((servingPredictions[servingKey][key].grams / servingPredictions[servingKey][key].customers * currentFormFieldValues[fieldIndex] / 1000).toFixed(3) + ' kg') : ' - ' }
                    </p>
                  </td>
                </tr>)
              })
            }
          })
        }
        html.push(<div>
          <p
            className="title is-3 is-size-4-mobile is-uppercase"
            style={{margin: '0'}}
          >
            <span style={{borderBottom: '4px solid #000'}}>
              {dateStr}
            </span>
          </p>
          <table className="weekEstimationTable">
            <tbody>
              {servingsHtml}
            </tbody>
          </table>
        </div>)
      }
    }
    
    return (
      <div>
        {html}
      </div>
    )
  }

  const view = () => {
    return (
      <div>
        <h1 className="title is-1 is-size-3-mobile is-uppercase"><Trans>Koneoppimisarvio</Trans></h1>
    
        <div style={{padding: '10px'}}>
          <div className="control">
            <div className="select is-large is-fullwidth hukkaselect">
              <HukkaSelect
                options={restaurantOptions}
                value={selectedRestaurant}
                placeholder={t('Valitse ravintola')}
                onChange={e => {
                  setSelectedRestaurant(e)
                  setLoading(true)
                  let newMonday = moment().isoWeekYear(yearNumber).isoWeek(weekNumber).startOf('isoWeek').format('YYYY-MM-DD')
                  let newSunday = moment().isoWeekYear(yearNumber).isoWeek(weekNumber).endOf('isoWeek').format('YYYY-MM-DD')
                  getMealServings(newMonday, newSunday, e.value)
                }}
              />
            </div>
          </div>
        </div>
        {weekNavigator}
        {errors && (
          <div style={{margin: '10px 0'}}>
            <ErrorMessage msg={errors} />
          </div>
        )}
        {loading === false && estimateData()}
        {loading === true && selectedRestaurant && (
          <p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>Ladataan...</p>
        )}
      </div>
    )
  }

  const checkPermission = () => {
    let reportSubId = hukkaServices.find(hs => hs.name === 'Koneoppimisarvio').id
    let hasPermission = false
    for (let i = 0; i < hukkaServiceSubscriptions.length; i++) {
      if (hukkaServiceSubscriptions[i].service === reportSubId && moment(new Date()) < moment(hukkaServiceSubscriptions[i].validUntil)) {
        i = hukkaServiceSubscriptions.length
        hasPermission = true
      }
    }
    
    return hasPermission ? view() : (<p className="is-size-2 is-size-3-mobile is-uppercase" style={{marginTop: '30px'}}>{t('Hei! Valitettavasti sinulla ei ole oikeuksia tämän palvelun käyttöön, mutta jos haluat tietää lisää, niin ota yhteyttä mailitse')}:<br /><a href="mailto:asiakaspalvelu@hukka.ai">asiakaspalvelu@hukka.ai</a></p>)
  }

  return <>
    {loggedIn && hukkaProfiles && hukkaServices && hukkaServiceSubscriptions && !readOnlyUser ? checkPermission() : ''}
  </>
	
}

export default WeekFoodsAi
